import React, { Fragment } from "react";
import styles from "./Modal.module.css";
import { createPortal } from "react-dom";

const Backdrop = (props) => {
  return <div onClick={props.onClose} className={styles.backdrop}>{props.children}</div>;
};

const ModalOverlay = ({ children, className, style }) => {
  return (
    <div
      style={style}
      className={className ? className : styles.modal}
    >
      {children}
    </div>
  );
};

function Modal({ onClose, children, className, style = {} }) {
  const portalElement = document.getElementById("overlays");

  return (
    <Fragment>
      {createPortal(<Backdrop onClose={onClose}>
        <ModalOverlay className={className} style={style}>
          {children}
        </ModalOverlay>
      </Backdrop>, portalElement)}      
    </Fragment>
  );
}

export default Modal;
