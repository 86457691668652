import Icon from "../../UI/Icon/Icon";

function SocialLinkButton({ link, className, label, icon }) {
  return (
    <a href={link} className={className}>
      <Icon icon={icon} />
      {label}
    </a>
  );
}

export default SocialLinkButton;
