import Icon from "../../UI/Icon/Icon";

function InfoLink({ icon, href, iconClasName, className, label }) {
  return (
    <a href={href} target="_blank" rel="noreferrer" className={className}>
      <Icon
        icon={icon}
        className={iconClasName}
      />
      {label}
    </a>
  );
}

export default InfoLink;
