function PersonDetails({ fullName, position, titleClass, descriptionClass }) {
  return (
    <>
      <label className={titleClass}>{fullName}</label>
      <p className={descriptionClass}>{position}</p>
    </>
  );
}

export default PersonDetails;
