import { Fragment, useState } from "react";
import MainImage from "../UI/MainImage/MainImage";
import Card from "../UI/Card/Card";
import gdtLogo from "../../../assets/gdt-logo-white.png";
import slogan from "../../../assets/Slogan.png";
import styles from "./Person.module.css";
import Icon from "../UI/Icon/Icon";
import SocialLinkButton from "./PersonComponents/SocialLinkButton";
import PersonDetails from "./PersonComponents/PersonDetails";
import LinkButton from "./LinkButton";
import { IoMdMail } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { BsPersonFillAdd } from "react-icons/bs";
import { GrCatalog } from "react-icons/gr";
import { IoMdClose } from "react-icons/io";
import PersonDetailsModal from "./PersonComponents/PersonDetailsModal";

function MobilePerson({ personData }) {
  const image = personData.image;
  const fullName = personData.fullName;
  const position = personData.position;
  const phone = personData.phone;
  const linkedin = personData.linkedin;
  const linkToCall = `tel:${phone}`;
  const linkToMail = `mailto:${personData.mail}`;
  const linkToWhatsApp = `https://wa.me/${phone.replace(/[+-]/g, "")}`;

  // const [windowSize, setWindowSize] = useState({
  //   width: window.innerWidth,
  //   height: window.innerHeight,
  // });

  const windowSize = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  const [showPersonDetails, setShowPersoneDetails] = useState(false);

  // useEffect(() => {
  //   const handelResize = () => {
  //     setWindowSize({
  //       width: window.innerWidth,
  //       height: window.innerHeight,
  //     });
  //   };
  //   window.addEventListener("resize", handelResize);
  // }, [windowSize]);

  const handlePersonDetailsModal = () => setShowPersoneDetails((prev) => !prev);

  return (
    <Fragment className={styles["fade-in"]}>
      {showPersonDetails && (
        <PersonDetailsModal
          headerClass={styles["modal-header"]}
          contentClass={styles["modal-content"]}
          icon={
            <IoMdClose
              onClick={handlePersonDetailsModal}
              size={25}
              className={styles["modal-close-button"]}
            />
          }
          fullName={fullName}
          position={position}
          phone={phone}
          email={personData.mail}
          toolTipClass={styles["tooltip"]}
          toolTipFadeOutClass={styles["tooltip-fadeout"]}
        />
      )}
      <MainImage src={image} />
      <div style={{ height: `${windowSize.height / 3 - 40}px` }} />
      <div
        className={styles["page-container"]}
        style={{
          height: `${windowSize.height - (windowSize.height / 3 - 40)}px`,
        }}
      >
        <Card>
          <div className={styles["page-inner-container"]}>
            <img
              alt="company-logo"
              src={gdtLogo}
              className={styles["company-logo"]}
            />

            <PersonDetails
              fullName={fullName}
              position={position}
              titleClass={styles["person-name"]}
              descriptionClass={styles["description-p"]}
            />

            <div className={styles["icons-row"]}>
              <SocialLinkButton
                link={linkToCall}
                icon={<FaPhone size={25} />}
                label={"CALL"}
                className={styles["socail-links"]}
              />
              <SocialLinkButton
                link={linkToMail}
                icon={<IoMdMail size={25} />}
                label={"EMAIL"}
                className={styles["socail-links"]}
              />
              <SocialLinkButton
                link={linkToWhatsApp}
                icon={<FaWhatsapp size={25} />}
                label={"WHATSAPP"}
                className={styles["socail-links"]}
              />
              <SocialLinkButton
                link={linkedin}
                icon={<FaLinkedinIn size={25} />}
                label={"LINKEDIN"}
                className={styles["socail-links"]}
              />
            </div>
            <LinkButton
              link={"https://www.gdentalt.com/Catalogs"}
              className={styles["link-to-catalog-button"]}
              icon={<GrCatalog size={25} style={{ margin: "5px" }} />}
              label={"Product Catalog"}
              labelClassName={styles["link-to-catalog-label"]}
            />
          </div>
          <img alt="company-logo" src={slogan} className={styles["slogan"]} />
          <a href="https://www.gdentalt.com/" className={styles["link-to-web"]}>
            www.gdentalt.com
          </a>
        </Card>
        <div
          onClick={handlePersonDetailsModal}
          className={styles["save-contact-button"]}
        >
          <Icon
            alternateStyle={{
              backgroundColor: "#FFF",
              color: "#0e809b",
              width: "45px",
              height: "45px",
              margin: "5px",
            }}
            icon={<BsPersonFillAdd size={25} />}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default MobilePerson;
