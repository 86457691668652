import styles from "./Person.module.css";

import { IoMdMail } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import ContactLink from "./PersonComponents/ContactLink";
import PersonDetails from "./PersonComponents/PersonDetails";
import Info from "../Info/Info";

function DesktopPerson({ personData }) {
  const image = personData.image;
  const fullName = personData.fullName;
  const position = personData.position;
  const phone = personData.phone;
  const mail = personData.mail;
  const linkedin = personData.linkedin;
  const linkToCall = `tel:${phone}`;
  const linkToMail = `mailto:${personData.mail}`;
  const linkToWhatsApp = `https://wa.me/${phone.replace(/[+-]/g, "")}`;

  const websiteTitle = document.querySelector("title");
  websiteTitle.innerHTML = fullName;

  return (
    <div className={styles["main-person-container"]}>
      <div className={styles["background-container"]}>
        <img alt="profile" src={image} className={styles["profile-image"]} />
        <PersonDetails
          fullName={fullName}
          position={position}
          className={styles["person-details-container"]}
        />
        <ContactLink
          className={styles["contact-link"]}
          IconClassName={styles["contact-link-icon"]}
          linkAddress={phone}
          icon={<FaPhone size={25} />}
          href={linkToCall}
        />
        <ContactLink
          className={styles["contact-link"]}
          IconClassName={styles["contact-link-icon"]}
          linkAddress={mail}
          icon={<IoMdMail size={25} />}
          href={linkToMail}
        />
        <ContactLink
          className={styles["contact-link"]}
          IconClassName={styles["contact-link-icon"]}
          linkAddress={"Message on WhatsApp"}
          icon={<FaWhatsapp size={25} />}
          href={linkToWhatsApp}
        />
        <ContactLink
          className={styles["contact-link"]}
          IconClassName={styles["contact-link-icon"]}
          linkAddress={"Connect in LinkedIn"}
          icon={<FaLinkedinIn size={25} />}
          href={linkedin}
        />
      </div>
      <div className={styles["social-container"]}>
        <Info />
      </div>
    </div>
  );
}

export default DesktopPerson;
