import styles from "./Info.module.css";
import gdtWhiteLogo from "../../../assets/gdt-logo-white.png";
import gdtLogo from "../../../assets/gdt-logo.png";
import slogan from '../../../assets/Slogan-blue.png';
import InfoLink from "./Infocomponents/InfoLinks";
import { FaYoutube } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";

function Info(props) {
  return (
    <div>
      <h2 className={styles["subtitles"]}>Enjoy our online store shopping experience</h2>
      <div className={styles["icons-row"]}>
        <InfoLink
          icon={<img alt="logo" className={styles["primary-logo"]} src={gdtLogo} />}
          iconClasName={styles["primary-logo-icon-container"]}
          label={"gdentalt.com"}
          className={styles["info-link"]}
          href={"https://www.gdentalt.com/"}
        />
        <InfoLink
          icon={<img alt="logo" className={styles["primary-logo"]} src={gdtWhiteLogo} />}
          innerClassName={styles["primary-logo"]}
          iconClasName={styles["primary-logo-icon-container-background-blue"]}
          label={"gdt-implants.com"}
          className={styles["info-link"]}
          href={"https://gdt-implants.com/"}
        />
      </div>

      <h2 className={styles["subtitles"]}>Check us out on</h2>
      <div className={styles["icons-row"]}>
        <InfoLink
          icon={<FaYoutube size={50} color="#FFF" />}
          innerClassName={styles["primary-logo"]}
          iconClasName={styles["primary-logo-icon-container-background-blue"]}
          label={"Youtube"}
          className={styles["info-link"]}
          href={"https://www.youtube.com/@gdtdentalimplants"}
        />
        <InfoLink
          icon={<FaTiktok size={50} />}
          innerClassName={styles["primary-logo"]}
          iconClasName={styles["primary-logo-icon-container"]}
          label={"Tiktok"}
          className={styles["info-link"]}
          href={"https://www.tiktok.com/@gdt_implants"}
        />
        <InfoLink
          icon={<FaFacebookF size={50} color="#FFF" />}
          innerClassName={styles["primary-logo"]}
          iconClasName={styles["primary-logo-icon-container-background-blue"]}
          label={"Facebook"}
          className={styles["info-link"]}
          href={"https://www.facebook.com/GDentalT/"}
        />
        <InfoLink
          icon={<BsInstagram size={50} />}
          innerClassName={styles["primary-logo"]}
          iconClasName={styles["primary-logo-icon-container"]}
          label={"Instagram"}
          className={styles["info-link"]}
          href={"https://www.instagram.com/gdt_implants/"}
        />
      </div>
        <img
            src={slogan}
            className={styles["slogn"]}
            alt="slogan"
        />

    </div>
  );
}

export default Info;
