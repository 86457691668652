import styles from "./Card.module.css";

function Card(props) {
  return <div className={styles["card-container"]}>
    <div className={styles["card-inner-container"]} style={{
          height: `${window.innerHeight - (window.innerHeight / 3 - 40)}px`,
        }}>{props.children}</div>
  </div>;
}

export default Card;
