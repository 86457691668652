import styles from './Icon.module.css';

function Icon({ icon, alternateStyle, className }) {
    
    return (  
        <div style={alternateStyle ? alternateStyle : {}} className={className ? className : styles["icon-container"]}>
            {icon}
        </div>
    );
}

export default Icon;