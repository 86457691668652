function LinkButton({ link, className, icon, label, labelClassName }) {
  return (
    <a
      href={link}
      className={className}
    >
      {icon}
      <div className={labelClassName}>{label}</div>
    </a>
  );
}

export default LinkButton;
