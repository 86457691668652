import Modal from "../../UI/Modal/Modal";
import React, { useState } from "react";

function PersonDetailsModal({
  headerClass,
  contentClass,
  icon,
  fullName,
  position,
  phone,
  email,
  toolTipClass,
  toolTipFadeOutClass
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const copyToClipboardFallback = (text) => {
    // Create a temporary textarea element
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.setAttribute("readonly", "");
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px"; // Position off-screen
    document.body.appendChild(textarea);

    // Select the text
    textarea.select();
    document.execCommand("copy"); // Execute copy command

    // Cleanup by removing the temporary element
    document.body.removeChild(textarea);
    // Feedback to the user
    
  };

  const copyToClipboard = async (text) => {
    setShowTooltip(true);
    try {
      await navigator.clipboard.writeText(text);      
      setTimeout(() => setShowTooltip(false), 1000);
    } catch (error) {
      console.error("Failed to copy, using fallback method: ", error);
      copyToClipboardFallback(text);
      setTimeout(() => setShowTooltip(false), 1000);
    }
    
  };
  return (
    <Modal>
      <div>
        <div className={headerClass}>
          {icon}

          <label>{fullName}</label>
          <label style={{ fontSize: "18px", marginTop: "5px" }}>
            {position}
          </label>
        </div>
        <div className={contentClass}>
          <label onClick={() => copyToClipboard(phone)}>
            <span style={{ color: "#94c94e", fontWeight: "bold" }}>
              Phone Number:
            </span>{" "}
            {phone}
          </label>
          <label onClick={() => copyToClipboard(email)}>
            <span style={{ color: "#94c94e", fontWeight: "bold" }}>
              Email Address:
            </span>{" "}
            {email}
          </label>
        </div>
        <div className={showTooltip ? toolTipClass : toolTipFadeOutClass}>Copied to clipboard!</div>
      </div>
    </Modal>
  );
}

export default PersonDetailsModal;
