import { Image } from "react-bootstrap";
import styles from "./MainImage.module.css";
import { useEffect, useState } from "react";

function MainImage({ src }) {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handelResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handelResize);
  }, [windowSize]);
  return (
    <div className={styles["main-image-container"]} style={{ height:`${windowSize.height / 2.5}px` }}>
      <Image src={src} className={styles["main-image"]} />
    </div>
  );
}

export default MainImage;
