import Icon from '../../UI/Icon/Icon';


function ContactLink({ icon, linkAddress, className, IconClassName, href }) {
    return (  
        <a href={href} className={className}>
            <Icon className={IconClassName} icon={icon}/>
            {linkAddress}
        </a>
    );
}

export default ContactLink;