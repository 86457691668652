import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MobilePerson from "./screens/MobileView/Person/Person";
import DesktopPerson from "./screens/DesktopView/Person/Person";
import { isMobile } from "react-device-detect";
import YakirImage from "./assets/yakir.png";
import AlonaImage from "./assets/alona.jpg";
import ItayImage from "./assets/Itay.jpg";

const persones = {
  yakir: {
    fullName: "Yakir Solcman",
    image: YakirImage,
    phone: "+972-54-779-0282",
    mail: "yakir@gdentalt.com",
    position: "CEO",
    linkedin: "https://www.linkedin.com/in/yakirsolcman/",
  },
  alona: {
    fullName: "Alona Ginzburg",
    image: AlonaImage,
    phone: "+972-51-501-3737",
    mail: "info@gdentalt.com",
    position: "Customer Success Manager",
    linkedin: "https://www.linkedin.com/in/alona-ginzburg-a44503214/",
  },
  itai: {
    fullName: "Itai Kacowicz",
    image: ItayImage,
    phone: "+972-51-5797058",
    mail: "itai@gdentalt.com",
    position: "Head of Business Development",
    linkedin: "https://www.linkedin.com/in/itai-kacowicz-11675998/",
  },
};

function getSubdomain() {
  const hostname = window.location.hostname;
  const parts = hostname.split(".");
  if (parts.length >= 3) {
    return parts[0]; // This is the subdomain
  }
  return null; // This means there is no subdomain
}

function App() {
  const subdomain = getSubdomain();
  if (isMobile) {
    return (
      <Router>
        <Routes>
          {/* <Route
            path={window.location.pathname}
            element={<MobilePerson personData={persones[window.location.pathname.split("/")[1]]} />}
          /> */}
          <Route
            path="/"
            element={<MobilePerson personData={persones[subdomain]} />}
          />
        </Routes>
      </Router>
    );
  } else {
    return (
      <Router>
        <Routes>
          <Route
            path="/"
            element={<DesktopPerson personData={persones[subdomain]} />}
          />
        </Routes>
      </Router>
    );
  }
}

export default App;
