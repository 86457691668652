import styles from './Icon.module.css';

function Icon({ icon, alternateStyle }) {
    
    return (  
        <div style={alternateStyle ? alternateStyle : {}} className={styles["icon-container"]}>
            {icon}
        </div>
    );
}

export default Icon;