function PersonDetails({fullName, position, className}) {
  return (
    <div className={className}>
      <h1>{fullName}</h1>
      <h3>{position}</h3>
    </div>
  );
}

export default PersonDetails;
